@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,200&display=swap");

.SignupFlow {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;

  .header-container {
    display: flex;
    justify-content: center;
    background-color: #242629;
    height: 6.063rem;
    align-items: center;

    .image {
      height: 2.375rem;
      width: 11.938rem;
    }
  }

  .business-details {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .business-details-wrapper {
    width: 37.26rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 1.25rem;
      color: white;
      margin-bottom: 0.68rem;
    }

    .main-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .inputs-wrapper {
      width: 42.5rem;
    }

    .dp-wrapper {
      margin-top: 0.6rem;
      margin-bottom: 2rem;
      height: 16.6rem;
      width: 16.6rem;
      background-color: #3b3c3f;
      border-radius: 0.313rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: Regular;
      font-size: 1.25rem;
      position: relative;
      cursor: pointer;

      .dp-overlayer {
        height: 16.6rem;
        width: 16.6rem;
        border-radius: 0.313rem;
        position: absolute;
        top: -0rem;
        left: 0;
        background-color: #000c;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
      }

      .dp {
        height: 16.6rem;
        // width: 16.6rem;
        border-radius: 0.36rem;

        img {
          vertical-align: text-bottom;
          width: auto;
        }
      }

      .upload-photo {
        display: block;
        text-align: center;

        .upload {
          height: 1.5rem;
          width: 1.5rem;
          margin-bottom: 0.375rem;
        }
      }
    }

    .profile-pic-wrapper {
      color: white;
      width: 13.5rem;
      height: 13.5rem;
      border-radius: 20%;
      background-color: #3b3c3f;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 1.5rem;
      .profile-pic {
        height: 13.5rem;
        width: auto;
        border-radius: 20%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        // margin-top: 0.35rem;
      }

      .select-icon {
        margin-top: -1rem;
        // margin-right: -14rem;
        background-color: white;
        color: white;
        width: 2.2rem;
        height: 2.2rem;
        // padding: 0.4rem;
        border-radius: 20%;
      }

      .account-image {
        height: 13.5rem;
        width: 13.5rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        // margin-top: 0.35rem;
      }
    }

    .copy-icon {
      // display: none;
      height: max-content;
      width: max-content;
      margin-left: .625rem;
      align-self: center;
      align-items: center;
      cursor: pointer;
  }

  .copy-icon:hover {
    display: block;
  }
  }

  .your-details-header {
    text-align: center;
  }

  .your-details-name-container {
    display: flex;
    justify-content: space-between;
  }

  .your-details-name-inputs {
    width: 48%;
  }

  .sub-text {
    text-align: left;
    font-size: 0.7rem;
    color: #b6b6b6;
    margin-top: 0.1rem;
    font-style: italic;
  }

  .check-container {
    color: #b5b5b5;
    text-align: left;
    font-size: 1rem;
    margin-top: 1.5rem;
    display: flex;
    cursor: pointer;
    span {
      a {
        color: #b5b5b5;
        text-decoration: underline;
      }
      .check-error {
        color: #d85757;
      }
    }
    .check-box {
      height: 1.375rem;
      width: 1.375rem;
      min-height: 1.375rem;
      min-width: 1.375rem;
      border: 1.5px solid #4e4e4e;
      border-radius: 0.313rem;
      margin-right: 1.625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .check {
        height: 0.875rem;
        width: 0.875rem;
      }
    }
    .checked {
      border-color: #147cff;
    }
    .checked-error {
      border-color: #d85757;
      border-width: 1px;
    }
  }
  .check-error {
    color: #d85757;
  }

  .SignupFooter {
    position: fixed;
    bottom: 0;
    height: 6.625rem;
    background-color: #242629;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .button-container {
      display: flex;
      column-gap: 1.25rem;
      margin-right: 6.063rem;
      margin-bottom: 2.438rem;
      margin-top: 1.688rem;
      justify-content: flex-end;
    }

    .progress-bar {
      bottom: 0;
      position: fixed;
      width: 100%;
      height: 1.063rem;
    }
  }
}

.domain-list {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.domain-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.87rem 0;
  position: relative; /* Required for pseudo-element positioning */

  .domain {
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.domain-item::after {
  content: ""; /* Required for pseudo-element */
  position: absolute;
  bottom: 0;
  margin-left: -0.75rem; /* Adjust the left position as needed */
  width: calc(100% + 1.5rem); /* Adjust the width as needed */
  height: 1px; /* Thickness of the line */
  background-color: #484b51; /* Line color */
}

.domain-item:last-child::after {
  display: none; /* Hide the line after the last domain */
}

.ViewDNS {
  width: 66rem;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: 400;

  .domain {
    color: #147cff;
    margin-top: 1.088rem;
    margin-bottom: 1.125rem;
  }

  table {
    width: 100%;
    font-size: 1.125rem;
    font-weight: 400;
    color: #7a7a7a;
    margin-bottom: 1.875rem;
    .top-border {
      border-top: 1px solid #484b51;
    }
    .bottom-border {
      border-bottom: 1px solid #484b51;
    }
    td {
      padding: 1rem;
      width: 5.437rem;
    }
    .dnsError {
      padding: 0rem 1rem;
      margin-top: -1.2rem;
      color: #d85757;
      font-style: italic;
      font-size: 1rem;
    }

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

@media only screen and (min-width: 1080px) {
  .create-account-width {
    width: 61.688rem !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1220px) {
  .SignupFlow {
    .business-details {
      // min-height: 60vh !important;
    }

    .main-wrapper {
      margin-top: 7rem !important;
    }
  }
}

@media only screen and (max-width: 970px) and (min-width: 750px) {
  .ViewDNS {
    width: 45rem !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 750px) {
  .SignupFlow {
    .business-details {
      // min-height: 60vh !important;
    }

    .main-wrapper {
      width: fit-content !important;
      margin-top: 7rem !important;
      flex-direction: column-reverse !important;
      align-items: center;
    }

    .ViewDNS {
      width: 60rem;
    }
  }
}

@media only screen and (max-width: 750px) and (min-width: 710px) {
  .SignupFlow {
    .business-details {
    }
    .main-wrapper {
      flex-direction: column-reverse !important;
      width: fit-content !important;
      align-items: center;
    }

    .ViewDNS {
      width: 45rem;
    }
  }
}

@media only screen and (max-width: 710px) and (min-width: 500px) {
  .SignupFlow {
    .ViewDNS {
      width: 30rem !important;
    }
  }
}

@media only screen and (max-width: 710px) {
  .SignupFlow {
    .button-container {
      margin-right: 0 !important;
      justify-content: center !important;
    }

    .business-details-wrapper {
      width: 26rem;
    }
    .main-wrapper {
      flex-direction: column-reverse !important;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
  .ViewDNS {
    width: 24rem !important;
  }
}

@media only screen and (max-width: 500px) {
  .create-account-width {
    width: 22.688rem !important;
  }

  .inputs-wrapper {
    width: 100% !important;
  }

  .account-image {
    height: 8.5rem !important;
    width: 8.5rem !important;
  }

  .profile-pic-wrapper {
    height: 8.5rem !important;
    width: 8.5rem !important;
  }

  .main-wrapper {
    flex-direction: column-reverse !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-top: 1rem !important;
    // margin-top: 1rem !important;
  }

  html {
    min-width: 0;
  }

  p {
    margin-top: 1rem;
  }

  .SignupFlow {
    .header-container {
      margin-bottom: 0rem;
    }

    .business-details {
      margin-bottom: 0rem;
      // justify-content: flex-start;
      h2 {
        // margin-top: 4rem;
      }
    }
    .button-container {
      margin-right: 0 !important;
      justify-content: center !important;
    }

    .business-details-wrapper {
      width: 20rem;
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-top: 0.5rem;
    }

    .your-details-header {
      // margin-bottom: -0.32rem !important;
    }

    .your-details-name-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .your-details-name-inputs {
      width: 100%;
      margin-top: 1.08rem;
    }

    .ButtonContainer-Grey {
      font-size: 1rem !important;
      // margin: 0 0 0 10px;
      text-align: center;
      padding: 0;
    }

    .domain {
      font-size: 1rem;
    }

    .domain-item {
      justify-content: space-between;
    }

    .domain-list {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 399px) and (min-width: 301px) {
  .SignupFlow {
    .ViewDNS {
      width: 22rem !important;
    }
  }
}

@media only screen and (max-width: 300px) {
  .SignupFlow {
    .business-details-wrapper {
      width: 15rem;
    }

    .ViewDNS {
      width: 20rem !important;
    }
  }
}

/* Style the track */
::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; /* Color of the thumb */
  border-radius: 16px; /* Rounded corners for the thumb */
}

/* Style the track (the area behind the thumb) */
::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}
