@import "../../../colors.scss";

//////////////////////////////////  Variables //////////////////////////////////////

$defultTextFont: "SF Pro Display", sans-serif;
$userNamePlaceHolder: "../../Assets/Icons/vuesax-linear-profile.svg";

::placeholder {
  font-weight: normal;
}

.mobileUI {
  display: none;
}
._passwordLinkContainer {
  height: 100vh;
  background-color: $white;
  display: flex;
  flex: 1;
  font-family: $defultTextFont;

  ._passwordLinkInnerContainerLeft {
    flex: 1;
    height: 100vh;
    .username-margin-top-passwordLink {
      margin-top: 3vh;
      margin-bottom: 5vh;
    }
    ::placeholder {
      color: $dark-gray;
      font-family: $defultTextFont;
      font-size: 16px;
    }
    .bixHeader {
      font-family: "Lato";
      font-size: 36px;
      // margin: 8px auto;
      font-weight: 700;
      text-align: center;
      color: $buttn-blue;
      span {
        font-weight: 400;
      }
    }
    .header-bottom-text {
      color: #9d9d9d;
      font-family: $defultTextFont;
      font-size: 0.875rem;
      margin-top: -0.5rem;
      text-align: center;
    }
    .submit-button {
      padding: 6% 2%;
      width: 80%;
      border-radius: 8px;
      background-color: $buttn-blue;
      color: $white;
      text-align: center;
      cursor: pointer;
      font-weight: bold;
      font-family: $defultTextFont;
      letter-spacing: normal;
      line-height: 0.94;
      margin-top: 6%;
      font-size: 22px;
      border-width: 0px;
      height: 50px;
      margin-left: 10%;
      margin-right: 10%;
    }

    .footerText {
      text-align: center;
      font-size: 16px;
      margin-top: 4vh;
      color: $small-text-black;
      font-family: $defultTextFont;
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
    .forgetTextLink {
      width: fit-content;
      font-size: 15px;
      color: $buttn-blue;
      cursor: pointer;
      font-weight: 500;
      font-family: $defultTextFont;
    }
    .forgetText {
      margin-top: -3vh;
    }
    .footerTextLink {
      color: $buttn-blue;
      margin-left: 5px;
      cursor: pointer;
      text-decoration: underline;
    }
    .checkboxWrapper {
      margin-top: 20px;
      display: flex;
      .check-box {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
      .checkboxLabel {
        font-family: $defultTextFont;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $text-black;
        align-items: center;
        align-self: center;
        align-content: center;
        display: flex;
      }
    }
    input.my-input:-internal-autofill-selected {
      background-color: rgb(255, 255, 255) !important;
      background-image: none !important;
      color: rgb(0, 0, 0) !important;
    }
    .wrapper {
      background: $white;
      margin: auto;
      padding: 20%;
    }
    .margin-top-20 {
      margin-top: 3rem;
    }
    .margin-top-10 {
      margin-top: 2.5rem;
    }

    .passwordLinkLabel {
      font-family: $defultTextFont;
      font-size: 28px;
      margin: 30px auto;
      font-weight: bold;
      text-align: left;
      color: $black;
    }
    .bullet {
      height: 0.4rem;
      min-width: 0.4rem;
      background: $buttn-blue;
      border-radius: 50%;
      margin-top: 0.5rem;
      margin-right: 8px;
    }
    .bullet-point {
      color: $buttn-blue;
      font-family: $defultTextFont;
      font-size: 1rem;
      display: flex;
      margin-bottom: 8.4rem;
      margin-left: -8px;
    }

    .form-container {
      width: 100%;
      background-color: $white;
    }

    .passwordLink-form {
      position: relative;
      // display: flex;
      flex-direction: column;
    }

    .passwordLink-input-box {
      background-color: $white;
      border: none;
      font-size: 16px;
      flex: 1;
      // outline: none;
    }
    .zp-input-container {
      display: flex;
      flex: 1;
      padding-bottom: 3px;
    }
    .fname {
      color: $dark-gray;
      font-size: 16px;
      font-family: $defultTextFont;
      width: max-content;
    }
    .icon {
      float: right;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
    .icon1 {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
    .danger-passwordLink {
      height: 12px;
      width: 12px;
      margin-right: 6px;
    }
    .inputdiv {
      border: 1px;
      border-bottom: $login-underline-gray 1px solid;
      input {
        width: 100%;
        border: none;
      }
    }

    .input-label {
      pointer-events: none;
      font-size: 14px;
      color: $dark-gray;
      font-family: $defultTextFont;
    }

    .input-label :focus-within label {
      transform: translate(0, 12px) scale(0.8);
      color: #0a53e4;
    }
  }
  ._passwordLinkInnerContainerRight {
    flex: 1;
    height: 100vh;
    background-color: #3799f4;
    .wrapper2 {
      margin: auto;
      padding: 20%;
    }
  }
  .danger-image {
    height: 14px;
    width: 14px;
    margin-right: 6px;
    align-self: center;
  }
}

@media only screen and (max-width: 768px) {
  .mobileUI {
    display: block;
  }

  ._passwordLinkContainer {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    display: table;
    ._passwordLinkInnerContainerLeft {
      .wrapper {
        padding: 0% 5%;
        height: 55vh;
        overflow-y: auto;
        padding-bottom: 15px;
        margin-top: -30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        position: absolute;

        width: 100%;
        .bixHeader {
          display: none;
        }
        .header-bottom-text {
          display: none;
        }
        .bullet-point {
          margin-bottom: 5rem;
        }
      }
    }

    ._passwordLinkInnerContainerRight {
      height: 45vh;
      display: table-header-group;
      .wrapper2 {
        padding: 10%;
      }
    }
  }
}
.condition-strength-2 {
  font-family: "roboto";
  font-size: 11px;
  position: relative;
  animation-name: slide-in;
  animation-duration: 0.5s;
  margin: 5px;
  .item2 {
    align-self: center;
    color: $dark-gray;
    display: flex;
  }
  .item {
    align-self: center;
    display: flex;
    color: $dark-gray;
  }
  .danger-image2 {
    height: 11px;
    width: 11px;
    margin-right: 6px;
    align-self: center;
  }
}

@keyframes slide-in {
  0% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}
