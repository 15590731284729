@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,200&display=swap");
/* Modal.css */
.modal {
  color: #EFE4E4;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
   /* Semi-transparent background */
}

.modal-content {
  background: #232628;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.5rem;
  height: 22.5rem;
}
.modal-button{
  
}

.signUpModal {
  width: 3.063rem;
  height: auto;
  margin-bottom: 2.313rem;
}

.signUpModalLottie{
  width: 10.063rem;
  height: auto;
  margin-bottom: 1.313rem;
}

@media only screen and (max-width: 500px) {
  .modal-content {
    width: 90%;
    height: 22.5rem;
  }
}
