.main-body-pay {
  background-color: #242629;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: aliceblue;
}
.input-container{
  max-width: 60%;
}
.pay-sub-text{
  margin-top: 0.75rem;
}
.pay-verify{
  margin-top: 1.5rem;
  margin-left: 0.5rem;
}
  @media only screen and (max-width: 600px) {
    .SignupWrapper {
      .SignupWrapper-container {
        width: 90%;
        .header-container {
          width: 90%;
          .logo-container {
            margin-left: -1.813rem;
            .back-icon {
              margin-left: 1.813rem;
            }
          }
        }
        .success-container {
          width: 90%;
        }
      }
    }
  }