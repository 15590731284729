.UsernameInput {
  width: 100%;
  height: 2.688rem;
  margin-top: 1.25rem;
  .zp-input-container {
    background-color: #242629;
    width: 100%;
    height: 2.688rem;
    display: flex;
    align-items: center;
    border-radius: 0.625rem;

    .input-wrapper {
      width: 90%;
      height: 2.688rem;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: "Poppins";
      font-size: 1rem;
      color: #fff;
      margin-left: 1.2rem;
     
    }
    .input-wrapper::placeholder {
      color: #545454;
    }
    .icon-container {
      width: 10%;
      height: 2.688rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 0.9rem;
      .icon {
        height: 1.063rem;
        width: 1.063rem;
        cursor: pointer;
      }
    }
    
  }
}
