@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,200&display=swap');

.ButtonContainer {
  height: 2.613rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.313rem;
  color: white;
  font-family: "Medium";
  font-size: 0.9rem;
  cursor: pointer;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  width: max-content;

  .sign-loader {
    border: 0.1rem solid white;
    border-radius: 50%;
    border-top: 0.1rem solid rgba(255, 255, 255, 0.581);
    width: 1rem;
    height: 1rem;
    -webkit-animation: sign-spinner 1s linear infinite;
    /* Safari */
    animation: sign-spinner 1s linear infinite;
  }

  @keyframes sign-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.ButtonContainer-Primary {
  background-color: #116fe5;
}

.ButtonContainer-Secondary {
  background-color: #fff;
  border: 1px solid #dadada;
  color: #000;
}

.ButtonContainer-Cancel {
  background-color: #4e4e4e;
}

.ButtonContainer-Danger {
  background-color: #db4242;
}

.ButtonContainer-Line {
  background-color: transparent;
  border: 1px solid #4E4E4E;
}

.ButtonContainer-Disabled {
  background-color: #dadada;
  cursor: not-allowed;
}

.ButtonContainer-Grey {
  background-color: #3E3E3E;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 400;
}