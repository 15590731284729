@import "../../../colors.scss";

//////////////////////////////////  Variables //////////////////////////////////////

.passwordChange {
  background-color: #1a1c1f;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ._passwordChangeContainer {
    font-family: "Poppins";
    width: 31%;
    margin: auto;
    .form-wrapper {
      .header-container {
        display: flex;
        justify-content: center;
        margin-bottom: 3.5rem;
        .image {
          height: 2.125rem;
          width: 9.563rem;
        }
      }
      .passwordChangewrapper {
        .header {
          color: $white;
          font-size: 1.25rem;
          margin-left: 1.2rem;
        }
        .expired{
          width: 100%;
          border: 1px solid #D85757;
          border-radius: 0.313rem;
          height: 2.688rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #D85757;
          font-size: 0.813rem;
          text-decoration: underline;
          column-gap: 0.813rem;
          margin-top: 1rem;
          padding-left: 1rem;
          padding-right: 1rem;
          .linkexp{
            height: 1.125rem;
            width: 1.25rem;
          }
        }
        .form-fail {
          font-size: 0.813rem;
          color: red;
          margin-left: 1.2rem;
          margin-top: 0.3rem;
          display: flex;
          align-items: center;
          .danger-login {
            height: 0.8rem;
            width: 0.8rem;
            margin-right: 0.5rem;
          }
        }
        .forgetText {
          color: #fff;
          text-align: right;
          font-weight: 200;
          text-decoration: underline;
          margin-top: 1.5rem;
          margin-bottom: 1.4rem;
          cursor: pointer;
        }
        .margin{
          height: 2.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .passwordChange {
    ._passwordChangeContainer {
      overflow-y: auto;
      width: 90%;
      .form-wrapper{
        .passwordChangewrapper{
          .expired{
            font-size: 0.75rem;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
          }
        }
      }
    }
  }
}
