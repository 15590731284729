@import "../../colors.scss";

.submit-button {
  width: 100%;
  background-color: $white;
  height: 2.688rem;
  border-radius: 0.625rem;
  color: $black;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #000; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.back-button {
  width: 100%;
  background-color: #1a1c1f;
  height: 2.688rem;
  border-radius: 0.625rem;
  color: $black;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #545454;
  border: 1px solid #545454;
  .loader {
    border: 2px solid #545454; /* Light grey */
    border-top: 2px solid #1a1c1f; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
